import * as React from 'react'
import { NextPage, NextPageContext } from 'next'
import Head from 'next/head'

import { BroodModel } from '@Interfaces'
import { BroodService } from '@Services'
import { SimpleTemplate, ErrorTemplate } from '@Components'

export interface OGTag {
  name: string
  value: string
}

interface ISlugPageProps {
  statusCode?: number
}

const SlugPage: NextPage<ISlugPageProps & BroodModel.GetPageResponse> = ({
  statusCode,
  meta,
  otherLanguageUrl,
  ...props
}) => {
  if (statusCode && (statusCode === 404 || statusCode === 500) && props.blocks) {
    return (
      <>
        {meta && (
          <Head>
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            {meta.oGtags &&
              meta.oGtags.map((tag: OGTag, i: number) => (
                <meta key={i} property={`${tag.name}`} content={`${tag.value}`} />
              ))}
            <link rel="canonical" href={meta.url} />
          </Head>
        )}
        <SimpleTemplate {...props} meta={meta} otherLanguageUrl={otherLanguageUrl} />
      </>
    )
  }

  if (statusCode && statusCode === 500 && !props.blocks) {
    return (
      <>
        {meta && (
          <Head>
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            {meta.oGtags &&
              meta.oGtags.map((tag: OGTag, i: number) => (
                <meta key={i} property={`${tag.name}`} content={`${tag.value}`} />
              ))}
            <link rel="canonical" href={meta.url} />
          </Head>
        )}
        <ErrorTemplate meta={meta} />
      </>
    )
  }

  let pageTitle: string = meta && meta.title ? meta.title : props.title

  return (
    <>
      {meta && (
        <Head>
          <title>{pageTitle}</title>
          {meta.description && <meta name="description" content={meta.description} />}
          {meta.keywords && <meta name="keywords" content={meta.keywords} />}
          {meta.oGtags &&
            meta.oGtags.map((tag: OGTag, i: number) => (
              <meta key={i} property={`${tag.name}`} content={`${tag.value}`} />
            ))}
          <link rel="canonical" href={meta.url} />
        </Head>
      )}
      <SimpleTemplate {...props} meta={meta} otherLanguageUrl={otherLanguageUrl} />
    </>
  )
}

SlugPage.getInitialProps = async ({ asPath, res }: NextPageContext) => {
  if (asPath) {
    //  split if has ?
    const pathArray = asPath.split('?')
    const slug = pathArray[0]
    let query = ''
    if (pathArray.length > 1) {
      query = pathArray[1]
    }

    const response: BroodModel.GetPageResponse = await BroodService.GetPage(slug, query)
    if (Object.keys(response).length === 0) {
      if (res) {
        res.statusCode = 500
      }

      return {
        meta: {
          title: 'Pagina is niet bereikbaar',
          description: 'Neem contact op met de systeembeheerder, of probeer het later nog eens.',
        },
        statusCode: 500,
      }
    }

    if (response.redirect) {
      if (res) {
        res.writeHead(301, {
          Location: response.redirect,
        })
        res.end()
      } else {
        window.location.href = response.redirect
      }

      return {}
    }
    // add querystring to response.data
    if (response && response.data) {
      response.data.query = query

      return response.data
    }
  }

  return {
    meta: {
      title: 'Default title',
      description: 'Default description',
    },
    blocks: {
      main: [],
    },
  }
}

export default SlugPage
